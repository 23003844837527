<template>
  <div class="api-request__wrapper">
    <div class="arw-header">
      <div class="arw-header__url">
        <LxpInput
          id="url"
          label="Enter URL or paste text"
          :value="apiUrl"
          v-model="apiUrl"
        />
      </div>
      <div class="arw-header__method">
        <LxpDropdown
          value="GET"
          dropdownClass="arw-header__method--dd"
          :dropdownArr="methodArr"
        ></LxpDropdown>
      </div>
      <LxpButton variant="secondary1" size="default" @click="onTest"
        >Test</LxpButton
      >
    </div>
    <div class="arw-main">
      <b-tabs
        nav-class="justify-content-md-center justify-content-lg-start"
        pills
        v-model="tabIndex"
        lazy
      >
        <b-tab title="Params" active>
          <div class="arw-main__header">
            <label>Query Params</label>
            <table
              class="arw-main__table"
              aria-describedby="Enables user to add query params"
            >
              <thead>
                <tr class="arw-main__title">
                  <th scope="col" style="width:5%"></th>
                  <th scope="col" style="width:40%">
                    Key
                  </th>
                  <th scope="col" style="width:40%">
                    Value
                  </th>
                  <th scope="col" style="width:5%"></th>
                </tr>
              </thead>

              <tbody class="arw-main__body">
                <tr
                  :class="{ 'arw-main__body--hover': d.isHover }"
                  v-for="(d, i) in paramData"
                  :key="i"
                  @mouseenter="d.isHover = true"
                  @mouseleave="d.isHover = false"
                >
                  <td>
                    <LxpCheckbox
                      :id="`arw-main__body-${i}`"
                      v-model="d.selected"
                      @input="onSelect('paramData')"
                    />
                  </td>
                  <td>
                    <p
                      :class="{ 'arw-main__body--empty': d.key === '' }"
                      v-if="!d.isKeyEdit"
                      @click="onEdit(d, 'isKeyEdit', 'paramData')"
                    >
                      {{ d.key === "" ? "Key" : d.key }}
                    </p>
                    <input
                      type="text"
                      placeholder="Key"
                      v-model="d.key"
                      @input="onInput(i, d, 'paramData')"
                      v-else
                    />
                  </td>
                  <td>
                    <p
                      :class="{ 'arw-main__body--empty': d.value === '' }"
                      v-if="!d.isValueEdit"
                      @click="onEdit(d, 'isValueEdit', 'paramData')"
                    >
                      {{ d.value === "" ? "Value" : d.value }}
                    </p>
                    <input
                      type="text"
                      placeholder="Value"
                      v-model="d.value"
                      @input="onInput(i, d, 'paramData')"
                      v-else
                    />
                  </td>
                  <td>
                    <img
                      src="@/assets/images/partner/shared/delete-black.svg"
                      alt="delete"
                      :class="d.isHover ? 'visible' : 'invisible'"
                      @click="onDelete(i, 'paramData')"
                      v-if="i + 1 !== 1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Headers">
          <div class="arw-main__header">
            <label>Headers</label>
            <table
              class="arw-main__table"
              aria-describedby="Enables user to add headers"
            >
              <thead>
                <tr class="arw-main__title">
                  <th scope="col" style="width:5%"></th>
                  <th scope="col" style="width:40%">
                    Key
                  </th>
                  <th scope="col" style="width:40%">
                    Value
                  </th>
                  <th scope="col" style="width:5%"></th>
                </tr>
              </thead>

              <tbody class="arw-main__body">
                <tr
                  :class="{ 'arw-main__body--hover': d.isHover }"
                  v-for="(d, i) in headerData"
                  :key="i"
                  @mouseenter="d.isHover = true"
                  @mouseleave="d.isHover = false"
                >
                  <td>
                    <LxpCheckbox
                      :id="`arw-main__body-${i}`"
                      v-model="d.selected"
                      @input="onSelect('headerData')"
                    />
                  </td>
                  <td>
                    <p
                      :class="{ 'arw-main__body--empty': d.key === '' }"
                      v-if="!d.isKeyEdit"
                      @click="onEdit(d, 'isKeyEdit', 'headerData')"
                    >
                      {{ d.key === "" ? "Key" : d.key }}
                    </p>
                    <input
                      type="text"
                      placeholder="Key"
                      v-model="d.key"
                      @input="onInput(i, d, 'headerData')"
                      v-else
                    />
                  </td>
                  <td>
                    <p
                      :class="{ 'arw-main__body--empty': d.value === '' }"
                      v-if="!d.isValueEdit"
                      @click="onEdit(d, 'isValueEdit', 'headerData')"
                    >
                      {{ d.value === "" ? "Value" : d.value }}
                    </p>
                    <input
                      type="text"
                      placeholder="Value"
                      v-model="d.value"
                      @input="onInput(i, d, 'headerData')"
                      v-else
                    />
                  </td>
                  <td>
                    <img
                      src="@/assets/images/partner/shared/delete-black.svg"
                      alt="delete"
                      :class="d.isHover ? 'visible' : 'invisible'"
                      @click="onDelete(i, 'headerData')"
                      v-if="i + 1 !== 1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="arw-main__response">
      <label>
        Response
      </label>
      <div class="arw-main__response--div" v-if="!hasErrorOnSave">
        <LxpPaginationWrapper
          ref="courseapilistwrapper"
          :dataFetchURL="apiUrl"
          nextPageLabel="next"
          :pageSizeValue="20"
          showNumberPagination
          :enableDynamicFetch="false"
          @count="updateCount"
          v-if="apiUrl !== '' && isTest"
        >
          <template v-slot:emptyState>
            <div class="arw-main__results--empty">
              <img
                src="@/assets/images/partner/course-api-request/warning.svg"
                alt="response"
              />
              <p>API test failed. Please review the URL and try again</p>
            </div>
          </template>
          <template v-slot:errorState>
            <div class="arw-main__results--error">
              <img
                src="@/assets/images/partner/course-api-request/warning.svg"
                alt="response"
              />
              <p>Something went wrong with your API request</p>
              <span>Please <a @click="onTest">try again</a>.</span>
            </div>
          </template>
          <template v-slot:data="slotProps">
            <div class="w-100">
              <div
                class="cw-main__results--card"
                v-for="(course, i) in transformData(slotProps.data)"
                :key="i"
              >
                <LxpCourseCard
                  :card-sub-title="course.org_short_name"
                  :card-title="course.name"
                  :card-image="
                    course.course_image_url
                      ? course.course_image_url
                      : require('@/assets/images/partner/courses/thumbnail.png')
                  "
                  :default-image="
                    require('@/assets/images/partner/courses/thumbnail.png')
                  "
                  tag-class="ls-resources__card--tag"
                  footer-class="course__card--footer"
                  @card-click="$parent.$emit('card-click', course.course_id)"
                  @card-subtitle-click="
                    $parent.$emit('card-subtitle-click', course.org_short_name)
                  "
                  :id="`course-card-${course.course_id}`"
                >
                  <template #footer>
                    <div class="course-card__footer">
                      <div
                        :class="[
                          'course-card__footer--div',
                          `course-card__${course.pacing}`
                        ]"
                      >
                        <img
                          :src="
                            course.pacing === 'self'
                              ? require('@/assets/images/partner/courses/self-paced-icon.svg')
                              : require('@/assets/images/partner/courses/instructor-led-icon.svg')
                          "
                          alt="pacing"
                        />
                        <p class="mb-0">{{ pacing(course) }}</p>
                      </div>
                      <div
                        class="course-card__footer--div course-card__language"
                      >
                        <img
                          src="https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/language.svg"
                          alt="pacing"
                        />
                        <p class="mb-0">{{ course.language }}</p>
                      </div>
                    </div>
                  </template>
                </LxpCourseCard>
                <course-details-popover :course="course" />
              </div>
            </div>
          </template>
        </LxpPaginationWrapper>
        <div class="arw-main__results--empty" v-else>
          <img
            src="@/assets/images/partner/course-api-request/response.svg"
            alt="response"
          />
          <p>Enter the URL and click Test to get a response</p>
        </div>
      </div>
      <div
        class="awr-main__response--div error"
        v-if="hasErrorOnSave && !isLoading"
      >
        <!-- TODO update the error message -->
        <p>Oops! It seems there's an issue importing a course.</p>
      </div>
    </div>
    <div class="arw-main__cta">
      <LxpButton
        variant="secondary1"
        :disabled="isLoading"
        @click="saveApi"
        v-if="count > 0 || response.length > 0"
        >Save API setup</LxpButton
      >
    </div>
  </div>
</template>

<script>
import {
  LxpInput,
  LxpDropdown,
  LxpButton,
  LxpCheckbox,
  LxpCourseCard,
  LxpPaginationWrapper
} from "didactica";
import { mapGetters } from "vuex";
import moment from "moment";
import CourseDetailsPopover from "../Shared/CourseDetailsPopover.vue";

export default {
  components: {
    LxpInput,
    LxpDropdown,
    LxpButton,
    LxpCheckbox,
    LxpCourseCard,
    LxpPaginationWrapper,
    CourseDetailsPopover
  },
  data() {
    return {
      tabIndex: 0,
      methodArr: [
        {
          name: "GET",
          id: "GET"
        }
      ],
      paramData: [
        {
          key: "",
          value: "",
          isKeyEdit: false,
          isValueEdit: false,
          isHover: false,
          selected: false,
          id: 1
        }
      ],
      headerData: [
        {
          key: "",
          value: "",
          isKeyEdit: false,
          isValueEdit: false,
          isHover: false,
          selected: false,
          id: 1
        }
      ],
      apiUrl: "",
      isTest: false,
      count: 0,
      headers: {
        headers: {}
      },
      response: [],
      isLoading: false,
      hasErrorOnSave: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    onEdit(d, key, param) {
      this.resetEditVal(param);
      d[key] = !d[key];
    },
    resetEditVal(param) {
      this[param].forEach(td => {
        td.isKeyEdit = false;
        td.isValueEdit = false;
      });
    },
    onDelete(i, param) {
      this[param].splice(i, 1);
    },
    onInput(i, d, param) {
      d.selected = true;
      if (param === "paramData") {
        this.setURL();
      } else {
        this.setHeaders();
      }
      if (i + 1 === this[param].length) {
        this.addNewRow(param);
      }
    },
    addNewRow(param) {
      let data = {
        key: "",
        value: "",
        isKeyEdit: false,
        isValueEdit: false,
        isHover: false,
        selected: false,
        id: this[param].length
      };
      this[param].push(data);
    },
    onSelect(param) {
      if (param === "paramData") {
        this.setURL();
      } else {
        this.setHeaders();
      }
    },
    setURL() {
      let url = this.apiUrl.split("?")[0];
      let queryParams,
        data = this.paramData.filter(
          td => (td.key !== "" || td.value !== "") && td.selected
        );
      data.forEach((td, index) => {
        if (td.key !== "" || td.value !== "") {
          if (index === 0) {
            queryParams = `?${td.key}=${td.value}`;
          } else {
            queryParams += `&${td.key}=${td.value}`;
          }
        }
      });
      this.apiUrl = `${url}${queryParams}`;
    },
    setHeaders() {
      let data = this.headerData.filter(
        td => td.key !== "" && td.value !== "" && td.selected
      );
      if (data.length > 0) {
        this.headers.headers = {};
      }
      data.forEach(td => {
        let obj = {};
        obj[td.key] = td.value;
        Object.assign(this.headers.headers, obj);
      });
    },
    pacing(course) {
      if (course.pacing === "self" || course.pacing === "instructor") {
        return course.pacing === "self" ? "Self-paced" : "Instructor-led";
      } else {
        return course.pacing;
      }
    },
    updateCount(c) {
      this.count = c;
    },
    onBodyInput(d) {
      this.bodyParam = d;
    },
    transformData(data) {
      // TODO in future update this based on partner api
      let arr = data.map(d => {
        return {
          name: d.name || d.title,
          org_short_name: this.userInfo.partner.org_short_name,
          course_id: d.course_id || d.course_key,
          course_image_url: d.course_image_url || d.media.image.raw,
          pacing: d.pacing,
          language: d.language || "en",
          start: moment.unix(d.start).isValid()
            ? moment().unix(d.start)
            : d.start,
          end: moment.unix(d.end).isValid() ? moment().unix(d.end) : d.end,
          created_at: moment.unix(d.created_at).isValid()
            ? moment().unix(d.created_at)
            : d.created_at,
          updated_at: moment.unix(d.updated_at).isValid()
            ? moment().unix(d.updated_at)
            : d.updated_at
        };
      });
      this.response = arr;
      return arr;
    },
    onTest() {
      this.isTest = true;
      if (this.$refs["courseapilistwrapper"]) {
        this.$refs["courseapilistwrapper"].reload();
      }
    },
    saveApi() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("endpoint", this.apiUrl);
      formData.append("request_method", "get");
      if (Object.keys(this.headers.headers).length > 0) {
        formData.append("headers", JSON.stringify(this.headers.headers));
      } else {
        formData.append("headers", '{"Content-Type": "application/json"}');
      }

      this.$store
        .dispatch("createPartnerCourses", formData)
        .then(() => {
          sessionStorage.setItem("loading-courses", true);
          this.$router.push({ name: "CourseList" });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasErrorOnSave = true;
        });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/tab.scss";
@import "../../../scss/floating.scss";
.api-request__wrapper {
  .arw-header__method {
    .arw-header__method--dd {
      margin-bottom: 0;
      background: $brand-primary-75;
      border-radius: 8px;
      .lx-select__dropdown {
        padding: 13px 16px;
        .lxp-select__dd--div {
          @include body-regular(400, $brand-neutral-900);
        }
        .lx-select__dd--div {
          align-items: center;
          width: 165px;
          p {
            margin-bottom: 0;
            @include body-regular;
            @include text-ellipsis;
            background: transparent;
          }
        }
      }
    }
  }
  .arw-main__header {
    .arw-main__header--response {
      .lxp-textarea {
        background: #fff;
        border: 1px solid $brand-neutral-200;
        padding: 8px;
        label {
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@mixin background() {
  padding: 16px;
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 16px;
}
@mixin border-radius {
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.api-request__wrapper {
  .arw-header {
    @include horizontal-space-between;
    @include background;
    .arw-header__url {
      flex-grow: 1;
    }
    .arw-header__method {
      margin: 0 16px;
    }
  }
  .arw-main {
    @include background;
    margin-top: 24px;
    .arw-main__header {
      label {
        @include label-large($brand-neutral-300, 500);
        margin: 16px 0;
      }
      .arw-main__table {
        width: 100%;
        tr {
          th {
            padding: 11px 16px;
            background-color: $brand-secondary1-50;
            @include border-radius();
            @include label-large($brand-neutral-700, 500);
          }
        }
        tbody {
          &:before {
            content: "@";
            display: block;
            line-height: 10px;
            text-indent: -99999px;
          }
          tr {
            border-bottom: 1px solid $brand-secondary1-100;
            &.arw-main__body--hover {
              background: $brand-primary-50;
            }
            td {
              background: transparent;
              padding: 8px;
              @include label-large($brand-neutral-700, 400);
              p {
                margin-bottom: 0;
                @include label-large($brand-neutral-900, 500);
                &.arw-main__body--empty {
                  @include label-large($brand-neutral-300, 400);
                }
              }
              img {
                cursor: pointer;
              }
              input {
                width: 100%;
                padding: 4px;
                border: 1px solid $brand-neutral-200;
                border-radius: 4px;
                @include label-large($brand-neutral-900, 500);
              }
            }
          }
        }
      }
      .arw-main__header--body {
        margin-bottom: 16px;
      }
      .arw-main__header--response {
        p {
          @include label-large($brand-neutral-300, 400);
          @include flex-horizontal-center;
          min-height: 100px;
        }
      }
    }
  }
  .arw-main__response {
    @include background;
    margin: 24px 0;
    label {
      @include label-large($brand-neutral-300, 500);
      margin-bottom: 8px;
    }
    .arw-main__response--div {
      clear: both;
      display: table;
      width: 100%;
      @include empty-state;
      .w-100 {
        display: flex;
        flex-wrap: wrap;
        .cw-main__results--card {
          width: 25%;
          margin-bottom: 30px;
        }
        @include course-card-footer;
      }
      .arw-main__results--empty,
      .arw-main__results--error {
        padding: 32px 0;
        @include center;
        .arw-main__results--empty-div {
          text-align: center;
          p {
            @include body-regular(500, $brand-neutral-900);
            margin: 16px 0 0;
          }
        }
      }
      .arw-main__results--error {
        p {
          margin-bottom: 4px;
        }
        span {
          @include body-regular(400, $brand-neutral-900);
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@include course-card-pointer;
</style>
